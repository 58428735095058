var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.showOverlay,"rounded":"sm"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%","width":"100%"}},[_c('b-tabs',{attrs:{"content-class":"mt-3","pills":""}},[_c('b-tab',{attrs:{"title":_vm.$t('COMMON.MAIN'),"active":""}},[_c('b-card-body',[_c('b-form-group',{attrs:{"id":"field-name","label-cols-lg":"2","label":_vm.$t('COMMON.NAME'),"label-for":"field-name"}},[_c('b-form-input',{attrs:{"id":"field-name-input"},model:{value:(_vm.dto.name),callback:function ($$v) {_vm.$set(_vm.dto, "name", $$v)},expression:"dto.name"}})],1),_c('b-form-group',{attrs:{"id":"field-active","label-cols-lg":"2","label":_vm.$t('COMMON.ACTIVE'),"label-for":"field-active-input"}},[_c('b-form-checkbox',{staticClass:"pt-2",attrs:{"id":"checkbox-active-input","name":"checkbox-active","switch":"","size":"lg"},model:{value:(_vm.dto.active),callback:function ($$v) {_vm.$set(_vm.dto, "active", $$v)},expression:"dto.active"}})],1),_c('b-form-group',{attrs:{"id":"field-secretCode","label-cols-lg":"2","label":_vm.$t('DISCOUNTS.SECRET_CODE'),"label-for":"field-secretCode-input"}},[_c('b-form-input',{attrs:{"id":"field-body-input"},model:{value:(_vm.dto.secretCode),callback:function ($$v) {_vm.$set(_vm.dto, "secretCode", $$v)},expression:"dto.secretCode"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('COMMON.TYPE'),"label-for":"discount-type","label-cols-lg":"2"}},[_c('b-form-select',{attrs:{"id":"discount-type","options":_vm.discountTypes},model:{value:(_vm.dto.discountTypeId),callback:function ($$v) {_vm.$set(_vm.dto, "discountTypeId", $$v)},expression:"dto.discountTypeId"}})],1),(
                _vm.dto.discountTypeId != null &&
                _vm.dto.discountTypeId != _vm.constants.DISCOUNT_TYPES.GIFT_PRODUCT
              )?_c('b-form-group',{attrs:{"id":"field-amountAsFractional","label-cols-lg":"2","label":_vm.$t('TRANSACTIONS.AMOUNT'),"label-for":"field-amountAsFractional-input"}},[_c('b-form-input',{attrs:{"id":"field-amountAsFractional-input"},model:{value:(_vm.dto.amountAsFractional),callback:function ($$v) {_vm.$set(_vm.dto, "amountAsFractional", $$v)},expression:"dto.amountAsFractional"}})],1):_vm._e(),(
                _vm.dto.discountTypeId != null &&
                _vm.dto.discountTypeId ==
                  _vm.constants.DISCOUNT_TYPES.FIX_AMOUNT_DISCOUNT
              )?_c('b-form-group',{attrs:{"id":"field-currency","label-cols-lg":"2","label":_vm.$t('ACCOUNTS.CURRENCY'),"label-for":"field-currency-input"}},[_c('b-form-select',{attrs:{"id":"discount-type","options":_vm.currencyTypes},model:{value:(_vm.dto.currencyId),callback:function ($$v) {_vm.$set(_vm.dto, "currencyId", $$v)},expression:"dto.currencyId"}})],1):_vm._e(),(
                _vm.dto.discountTypeId != null &&
                _vm.dto.discountTypeId == _vm.constants.DISCOUNT_TYPES.GIFT_PRODUCT
              )?_c('b-form-group',{attrs:{"id":"field-products","label-cols-lg":"2","label":_vm.$t('MENU.PRODUCTS'),"label-for":"field-products"}},[_c('multi-suggestion',{attrs:{"suggestion-processor":"productSuggestionProcessor","element-name":"Product","elements-name":"Products","selected-elements":_vm.selectedProducts,"addErrorNotification":true},on:{"on-element-added":_vm.onProductSelected,"on-remove-element":_vm.removeProduct}})],1):_vm._e(),_c('b-form-group',{attrs:{"id":"field-reusable","label-cols-lg":"2","label":_vm.$t('DISCOUNTS.REUSABLE'),"label-for":"field-reusable-input"}},[_c('b-form-checkbox',{staticClass:"pt-2",attrs:{"id":"checkbox-reusable-input","name":"checkbox-reusable","switch":"","size":"lg"},model:{value:(_vm.dto.reusable),callback:function ($$v) {_vm.$set(_vm.dto, "reusable", $$v)},expression:"dto.reusable"}})],1),_c('b-form-group',{attrs:{"id":"field-onceForUser","label-cols-lg":"2","label":_vm.$t('DISCOUNTS.ONCE_FOR_USER'),"label-for":"field-onceForUser-input"}},[_c('b-form-checkbox',{staticClass:"pt-2",attrs:{"id":"checkbox-onceForUser-input","name":"checkbox-onceForUser","switch":"","size":"lg"},model:{value:(_vm.dto.onceForUser),callback:function ($$v) {_vm.$set(_vm.dto, "onceForUser", $$v)},expression:"dto.onceForUser"}})],1)],1)],1)],1),_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.onSaveClicked}},[_vm._v(_vm._s(_vm.$t("COMMON.SAVE")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }